/** @file
 * Comportamientos de las vacaciones en la ficha de un colaborador
 * Maneja el datepicker del formulario de agregar/solicitar vacaciones
 */
import onmount, { $ } from 'onmount';
import { error } from '../../../../../../../../app/assets/javascripts/lib/buk/notice.js';
import { toCode } from '../../../../../../../../app/assets/javascripts/lib/string.js';

onmount('#vacacion-form', function () {
  const self = this;
  const url = $(this).attr('action');

  $(this).on('submit', function (e) {
    e.preventDefault();
    const formData = $(self).serialize();
    // deshabilitamos submit
    const previousButtonName = $(this).find(':submit').val();
    $(this).find(':submit').val('Procesando ...');
    $(this).find(':submit').prop('disabled', true);
    post(formData)
      .done(function (data) {
        // TODO: Amplica a ambos flujos?
        // si es ok venimos del flujo de no requiere firma
        if (data === 'ok') {
          // ocultamos posibles errores
          $('#vacacion-form-errors').hide();
          // período de vacaciones creado/actualizado con éxito, recargamos

          sendAmplitudEvent();

          history.pushState({}, '', `${location.pathname}?app-review=true${location.hash}`);
          location.reload();
        }
        else {
          sendAmplitudEvent();
          history.pushState({}, '', `${location.pathname}?app-review=true${location.hash}`);

          // Abrimos el modal de firmar documentos utilizando un link auxiliar (autogenerado en algunos casos)
          showSignFormModal(data);

          // Recargamos la página si cierra el modal de firma
          reloadOnModalHide($('.modal'));
        }
      })
      .fail(function (data) {
        if (data.status === 422) {
          // Error de validación
          // limpiamos ul de los posibles errores anteriores
          $('#vacacion-form-errors div.alert ul').html('');
          // recorremos el json con los mensajes de error
          $.each(data.responseJSON.error, function () {
            // imprimimos los mensajes en una lista
            $('#vacacion-form-errors div.alert ul').append('<li>' + this + '</li>');
          });
          // mostramos alerta de error
          $('#vacacion-form-errors').show();
        }
        else {
          // error 500
          error('Error desconocido');
        }
        // habilitamos submit
        $(self).find(':submit').val(previousButtonName);
        $(self).find(':submit').prop('disabled', false);
      });
    return false;
  });

  // métodos locales
  function post(params) {
    return $.ajax({
      method: 'POST',
      dataType: 'json',
      url: url,
      data: params,
    });
  }

  // Se permite seleccionar fines de semana en el datepicker al solicitar/agregar vacaciones segun el tipo de vacacion
  $('#vacacion_vacation_type_id').change(async function () {
    const vacationTypesDiasCorridos = $(this).data('vacation-types-dias-corridos');
    const vacationTypesDiasLaborales = $(this).data('vacation-types-dias-laborales');

    $('#sign-request-button').on('form-toggle-enable', function (_, state) {
      if (state) {
        $('#save-request-button').hide('slow');
      }
      else {
        $('#save-request-button').show('slow');
      }
    });

    if (vacationTypesDiasCorridos !== undefined) {

      // Se guardan las fechas deshabilitadas en un array
      var datePickerObj = $('#vacacion_start_date')[0];
      var keys = Object.keys(datePickerObj);
      for (var i = 0; i < keys.length; i++) {
        var val = datePickerObj[keys[i]];
        if (val.dateDatesDisabled !== undefined) {
          var defaultDatesDisabled = val.dateDatesDisabled;
        }
      }

      const vacationTypeSelected = parseInt($(this).val());
      let daysOfWeekDisabled = [0, 6];
      if (vacationTypesDiasCorridos.includes(vacationTypeSelected)) {
        daysOfWeekDisabled = [];
      }
      else if (vacationTypesDiasLaborales.includes(vacationTypeSelected)) {
        daysOfWeekDisabled = $(this).data('employee-not-working-days');
      }

      const datesDisabled = vacationTypesDiasCorridos.includes(vacationTypeSelected) ? [] : defaultDatesDisabled;

      await import('../../../../../../../../app/assets/javascripts/vendor/datepicker.js');
      $('#vacacion_start_date').datepicker('setDaysOfWeekDisabled', daysOfWeekDisabled);
      $('#vacacion_start_date').datepicker('setDatesDisabled', datesDisabled);
      $('#vacacion_end_date').datepicker('setDaysOfWeekDisabled', daysOfWeekDisabled);
      $('#vacacion_end_date').datepicker('setDatesDisabled', datesDisabled);
    }
  });
});

// Script para que se genere el código del tipo de vacacion automaticamente el form de creación
$(document).on('turbolinks:load', function () {
  function handleNameChange() {
    $('#vacation_type_code').val(toCode($('#vacation_type_name').val()));
  }

  $('#vacation_type_name').keyup(handleNameChange);
});

// Script para recargar la página luego de haber firmado correctamente un certificado de vacación.
onmount('#tabla_vacaciones', function () {
  onmount("form[id='last_signature_step']", function () {
    reloadOnModalHide($('.modal'));
  });
});

onmount('#vacacion_vacation_type_id', function () {
  const vacationWeekends = $(this).data('vacation-weekends');
  if (vacationWeekends !== undefined) {

    if (vacationWeekends.includes(parseInt($(this).val()))) {
      $('#vacacion_start_date').datepicker({
        daysOfWeekDisabled: [],
        datesDisabled: [],
      });
      $('#vacacion_end_date').datepicker({
        daysOfWeekDisabled: [],
        datesDisabled: [],
      });
    }
  }
});

function reloadOnModalHide(modals) {
  // Hace que el ultimo modal abierto al cerrarse recargue la página.
  const lastModal = $(modals[modals.length - 1]);
  $(lastModal).on('hidden.bs.modal', function () {
    window.location.reload(true);
  });
}

function sendAmplitudEvent() {
  const form = $('#vacacion-form');
  const source = form.data('amplitudeSource');
  const vacationType = $('#vacacion_vacation_type_id').find(':selected').text();
  const parcialDay = $('#vacacion_partial_day').is(':checked');

  if (source) {
    form.trigger('amplitudeTracking', {
      message: 'Request_Vacations',
      data: {
        source,
        'vacation_type': vacationType,
        'parcial_day': parcialDay,
      },
    });
  }
}

function showSignFormModal(vacacion) {
  const id = vacacion.vacation_id;
  const signUrl = `/vacacions/${id}/sign_vacacion`;
  let $signButton = $('#firma_aux_vacacion');

  $('.modal').modal('hide');

  if ($signButton[0]) {
    const amplitudeSource = $signButton.data('amplitudeSource');

    $signButton = $signButton[0];

    $signButton.setAttribute('href', `${signUrl}?source_request=${amplitudeSource}`);
  }
  else {
    const $vacacionForm = $('#vacacion-form');
    const modalSignTitle = $vacacionForm.data('modalSignTitle');
    const amplitudeSource = $vacacionForm.data('amplitudeSource');

    $signButton = $(`<a data-modal-title="${modalSignTitle}"
      data-modal="true"
      data-modal-size="md"
      data-turbolinks="false"
      href="${signUrl}?source_request=${amplitudeSource}" hidden>
      </a>`).appendTo('body');
    $signButton = $signButton[0];
  }

  $signButton.click();
}
