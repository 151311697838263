/** @file
*
* Permite el comportamiento reactivo para el componente table actions cuando existe overflow horizontal
*/

import onmount from 'onmount';

onmount('.fiji-body', function () {

  initializeMemberActionsSticky.call(this);

  // Detectar cambios en el body de la tabla
  const $fijiTableBody = $('.fiji-table-body');
  $fijiTableBody.each((_, element) => {
    observeTableBodyChanges(element, () => {
      initializeMemberActionsSticky.call(this);
    });
  });
});

/** Observar cambios en el body de la tabla */
function observeTableBodyChanges(targetElement, callback) {
  const observer = new MutationObserver(callback);
  observer.observe(targetElement, { childList: true, subtree: true });
  return observer;
}

function initializeMemberActionsSticky() {
  const wrapper = document.querySelector('.content-wrapper');
  const tableResponsive = this.querySelector('.table-responsive');
  const fijiTable = this.querySelector('.fiji-table');
  const stickyColumns = fijiTable.querySelectorAll('.sticky-column--active');

  if (!fijiTable || !wrapper || !tableResponsive || !stickyColumns.length > 0) return;
  // Inicilizar ResizeObserver
  const resizeObserver = initializeResizeObserver(stickyColumns, fijiTable, tableResponsive);
  // Detectar overflow y aplicar el estilo sticky
  const hasOverflow = hasHorizontalScroll(fijiTable, tableResponsive);
  applyStickyStyle(stickyColumns, hasOverflow);

  // Observar cambios en el tamaño de la tabla
  resizeObserver.observe(tableResponsive);
  // desconectar observador al desmontar
  return resizeObserver;
}

/** Verifica si existe overflow horizontal en la tabla */
function hasHorizontalScroll(element, container) {
  return element.getBoundingClientRect().width > container.getBoundingClientRect().width;
}

/** Inicializa el observador de redimensionamiento para detectar overflow */
function initializeResizeObserver(columns, table, wrapper) {
  return new ResizeObserver(() => {
    const hasOverflow = hasHorizontalScroll(table, wrapper);
    applyStickyStyle(columns, hasOverflow);
  });
}

/** Aplica estilo sticky a las columnas en caso de overflow */
function applyStickyStyle(columns, hasOverflow) {
  const isMobile = window.matchMedia('(max-width: 959px)').matches;
  columns.forEach((column) => {
    if (isMobile || hasOverflow) {
      column.classList.add('sticky-column--active');
      column.classList.remove('sticky-column');
    }
    else {
      column.classList.add('sticky-column');
      column.classList.remove('sticky-column--active');
    }
  });
}
