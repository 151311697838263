/** @file Archivo JS para capacitaciones
 *
*/
import onmount, { $ } from 'onmount';
import { warning } from '@assets/javascripts/lib/buk/alert.js';
import ModalBuilder from '@assets/javascripts/lib/modal-builder';

onmount('.training-plan-course-card', function () {
  const courseCard = $(this);
  const btnDelete = courseCard.find('[data-course-id]');
  const courseId = btnDelete.data('course-id');

  btnDelete.on('click', function () {
    const select2Courses = $('.training_plan_available_courses').find('select');
    select2Courses.val(select2Courses.val().filter(function (value, _index, _arr) { return value != courseId; }));
    select2Courses.trigger('change');
    courseCard.remove();
  });
});

onmount('.training_plan_employee_ids', function () {
  const inputParticipants = $(this);
  const btnAddParticipants = $('[data-name="btn_add_participants_training_plan"]');
  const titleModal = $('#edit_participant_training_plan_modal').find('.modal-title');

  inputParticipants.on('change', function () {
    if (inputParticipants.val().length > 0) {
      btnAddParticipants.text('Editar Participantes');
      titleModal.text('Editar Participantes');
    }
    else {
      btnAddParticipants.text('Agregar Participantes');
      titleModal.text('Agregar Participantes');
    }
  });
});

onmount('#form-version-modal-edit', function () {
  const modal = $(this);
  // inputs del modal
  const modalInputName = modal.find('[data-version-input="name"]');
  const modalInputTeacher = modal.find('[data-version-input="teacher"]');
  const modalInputStartDate = modal.find('[data-version-input="start_date"]');
  const modalInputEndDate = modal.find('[data-version-input="end_date"]');
  const modalInputDuration = modal.find('[data-version-input="duration"]');
  const modalInputProvider = modal.find('[data-version-input="provider"]');

  // inputs de una version en particular
  let inputName = null;
  let inputTeacher = null;
  let inputStartDate = null;
  let inputEndDate = null;
  let inputDuration = null;
  let inputProvider = null;

  modal.find('[data-edit-version]').on('click', function () {
    if ($.trim(modalInputName.val()).length) {
      inputName.val(modalInputName.val());
      inputTeacher.val(modalInputTeacher.val());
      inputStartDate.val(modalInputStartDate.val());
      inputEndDate.val(modalInputEndDate.val());
      inputDuration.val(modalInputDuration.val());
      inputProvider.val(modalInputProvider.val());

      modalInputName.val('');
      modalInputTeacher.val('');
      modalInputStartDate.val('');
      modalInputEndDate.val('');
      modalInputDuration.val('');
      modalInputProvider.val('');
    }
    else {
      warning('Nombre no puede estar en blanco');
    }
  });

  onmount('[data-btn-version-edit]', function () {
    $(this).on('click', function () {
      const version =  $(this).parents('.nested-fields');
      inputName = version.find('[data-copy-from="[data-version-name-input]"]');
      inputTeacher = version.find('[data-copy-from="[data-version-teacher-input]"]');
      inputStartDate = version.find('[data-copy-from="[data-version-start-date-input]"]');
      inputEndDate = version.find('[data-copy-from="[data-version-end-date-input]"]');
      inputDuration = version.find('[data-copy-from="[data-version-duration-input]"]');
      inputProvider = version.find('[data-copy-from="[data-version-provider-input]"]');

      modalInputName.val(inputName.val());
      modalInputTeacher.val(inputTeacher.val());
      modalInputStartDate.val(inputStartDate.val());
      modalInputEndDate.val(inputEndDate.val());
      modalInputDuration.val(inputDuration.val());
      modalInputProvider.val(inputProvider.val());
    });
  });
});

onmount('[data-participant-versions]', async function () {
  const assignSections = $(this);
  const btnActions = assignSections.find('button[data-toggle="dropdown"]');
  btnActions.addClass('disabled');

  const massiveSelect = assignSections.find('[data-id="massive-version-select"]');

  const table = assignSections.find('#table-participant-sections');
  await table.waitDataTable();

  assignSections.on('click change', '.participant_version_checkbox', function () {
    const totalChecked = table.find('input[type=checkbox]:checked:not([data-select-all])').length;
    if (totalChecked === 0) {
      btnActions.addClass('disabled');
    }
    else {
      btnActions.removeClass('disabled');
    }
  });

  assignSections.on('click', '[data-edit-massive-versions]', function () {
    const selectedValue = massiveSelect.val();
    const checked = table.find('input[type=checkbox]:checked:not([data-select-all])');
    checked.each(function () {
      $(`select[data-id="${this.id}"]`).val(selectedValue).trigger('change');
    });
  });
});

onmount('[data-course-request]', async function () {
  const requestTab = $(this);
  const btnActions = requestTab.find('#course_requests_massive_actions');
  const table = requestTab.find('#datatable_courses_requests');

  requestTab.on('click change', '.course-request-checkbox', function () {
    const resolvedCourses = table.find('input[type=checkbox].resolved:checked').length;
    if (resolvedCourses) {
      btnActions.addClass('disabled');
    }
    else {
      btnActions.removeClass('disabled');
    }
  });

  requestTab.on('click', '.massive-action:not(.disabled)', function (event) {
    const resolution = $(this).attr('resolution');
    const ids = [];

    requestTab.find('.course-request-checkbox:checked:not([data-select-all])').each(function () {
      const id = $(this).attr('data-id');
      // if (id) {
      ids.push(id);
      // }
    });

    if (ids.length === 0) {
      warning('Seleccione al menos una solicitud');
    }
    else {
      const modalUrl = '/courses/massive_resolve_modal';
      const title = resolution === 'approve' ? 'Aprobar masivamente' : 'Rechazar masivamente';
      const size = 'md';
      const params = { course_ids: ids, resolution: resolution };

      const modalBuilder = new ModalBuilder();
      modalBuilder.setFooter('');
      modalBuilder.setSize(size).show().loadFromRemote(modalUrl, params, title, 'get');
      modalBuilder.getModal().on('modal:reload', () => modalBuilder.loadFromRemote(modalUrl, params, title));
      event.preventDefault();
      return false;
    }
  });
});

// hack que permite abrir una nueva ventana desde safari al realizar un ajax.
onmount('#go_to_boost', function () {
  $(this).on('click', function () {
    const url = '/courses/go_to_boost';
    const newWindow = window.open();
    $.ajax({
      method: 'GET',
      dataType: 'json',
      url: url,
      success: function (data) {
        newWindow.location = data.external_url;
      },
      error: function (xhr) {
        const errors = $.parseJSON(xhr.responseText).errors;
        warning(errors);
      },
    });
  });
});

onmount('a[href="/courses/sign_in_boost"]', function () {
  let isDisabled = false;
  const timeToEnableButton = 3000;

  $(this).on('click', function (e) {
    if (isDisabled) {
      e.preventDefault();
    }

    if (!isDisabled) {
      setTimeout(() => {
        isDisabled = false;
      }, timeToEnableButton);
    }
    isDisabled = true;
  });
});

onmount('a[href^="/lms_integration/lms_authentication/sign_in"]', function () {
  const ACTIONS = {
    1: { key: 'SIGN_IN_LMS', value: 'Go_LMS' },
    2: { key: 'SIGN_IN_LMS_IN_COURSE', value: 'View_Course' },
  };

  $(this).on('click', function () {
    const element = $(this);
    const url = element.attr('href');
    const params = new URLSearchParams(url.split('?')[1]);
    const strategyId = Number(params.get('strategy_id'));

    if (ACTIONS[strategyId]) {
      const action = ACTIONS[strategyId];
      const data = { strategy: action.key };
      element.trigger('amplitudeTracking', { message: action.value, data: data });
    }
  });
});
