/** @file TODO: documentar */
/* eslint camelcase: "off" */
import onmount from 'onmount';
import { error } from '../lib/buk/notice';
import { EDITOR_OPTIONS } from '../components/ckeditor/templateinput';

onmount('#document_template_legal_agent_sign', function () {
  const checkbox = $(this);
  checkbox.on('click', function () {
    if (checkbox.is(':checked') === false && $('#document_template_second_legal_agent_sign').is(':checked')) {
      $('#document_template_second_legal_agent_sign').trigger('click');
    }
  });
});

onmount('#document_template_second_legal_agent_sign', function () {
  $(this).on('change', function () {
    const nombre = $('#select2-document_template_first_signee-container').text();

    if (
      nombre !== 'Representante legal' &&
      nombre !== 'Supervisor del empleado'
    ) {
      const selected_signee = $(
        `#document_template_second_signee option:contains('${nombre}')`
      );
      selected_signee.remove();
    }

    if ($('#document_template_legal_agent_sign')?.prop('checked') === false) {
      $('#document_template_second_legal_agent_sign')?.prop('checked', false);
      return;
    }

    $('.document_template_second_signee')?.removeAttr('hidden');
    if (
      $('#document_template_second_legal_agent_sign')?.prop('checked') === false
    )
      $('.document_template_second_signee')?.attr('hidden', true);
  });
});

export const SIMPLE_EDITOR_OPTIONS = () => ({
  shiftMode: CKEDITOR.ENTER_BR,
  base64image: { allow_remote: false },
  disallowedContent: 'img{width,height,float}',
  extraAllowedContent: 'img[width,height,align]',
  extraPlugins: 'tableresize,base64image,justify',
  toolbar: [
    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike'] },
    {
      name: 'align',
      items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
    },
    { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
    { name: 'insert', items: ['Table', 'base64image'] },
  ],
  removePlugins: ['elementspath'],
  versionCheck: false,
});

onmount('[data-editor="ckeditor_simple"]', async function (obj) {
  const { loadCKeditor, CKeditorVersion, loadCKeditor5 } = await import('../components/ckeditor/load');

  if (CKeditorVersion() === '4') {
    await loadCKeditor();
    const editor = CKEDITOR.replace(this, SIMPLE_EDITOR_OPTIONS());
    obj.editor = editor;
    editor.on('change', async function () {
      editor.updateElement(); // Esto actualiza el value del textarea
    });
    // permite agregar un texto previo, por ejemplo al editar un formulario
    const current_data = $('#current_editor_value').val();
    if (current_data) obj.editor.setData(current_data);
  }
  else {
    await loadCKeditor5(this, obj);
  }
});

onmount('.rich-text', async function (obj) {
  const { loadCKeditor, CKeditorVersion, loadCKeditor5 } = await import('../components/ckeditor/load');
  const { RICH_EDITOR_OPTIONS } = await import('@assets/javascripts/components/ckeditor/custom_configs');

  if (CKeditorVersion() === '4') {
    await loadCKeditor();
    const mailer_template = $(this).data('mailer');
    obj.editor = CKEDITOR.replace(this, EDITOR_OPTIONS(mailer_template));
  }
  else {
    await loadCKeditor5(this, obj, RICH_EDITOR_OPTIONS());
  }
});

onmount(
  '#document_template_content',
  async function () {
    const original_vars_url = $('#variables-button').attr('href');
    const original_template_type = $('#template-type-input').val();
    const placeholder_vars_url = original_vars_url?.replace(
      original_template_type,
      '{template_type}'
    );

    $('#template-type-input').change(function () {
      const template_type = this.value;
      $('#variables-button').attr(
        'href',
        placeholder_vars_url?.replace('{template_type}', template_type)
      );
    }).trigger('change');
  },
  function (obj) {
    obj.editor.destroy();
  }
);

onmount('[data-behavior=toggle_document_template]', function () {
  const $button = $(this);
  const $form = $button.closest('form');
  const $modal = $('#cron_schedule_modal');

  function setStarted() {
    $button
      .removeClass('btn-destructive')
      .addClass('btn-primary')
      .attr('title', 'Detener');
    $button
      .html('<i class="fa fa-stop"></i><span> Iniciado</span>')
      .prop('disabled', false);
  }

  function setStopped() {
    $button
      .removeClass('btn-primary')
      .addClass('btn-destructive')
      .attr('title', 'Iniciar');
    $button
      .html('<i class="fa fa-play"></i><span> Detenido</span>')
      .prop('disabled', false);
  }

  function cancelChange() {
    if ($button.is('.btn-primary')) {
      setStarted();
    }
    else {
      setStopped();
    }
  }

  $form.on('ajax:send', function () {
    $button
      .html($button.is('.btn-primary') ? 'Deteniendo...' : 'Iniciando...')
      .prop('disabled', true);
  });

  $form.on('ajax:success', function (event) {
    const result = event.detail[2];
    const json = tryParseJSON(result.response);
    if (json && json.stopped) {
      setStopped();
    }
    else {
      $modal.html(result.response).modal('show');
      $modal.on('hidden.bs.modal', function () {
        $modal.off('hidden.bs.modal');
        cancelChange();
      });
    }
  });
  $form.on('ajax:error', function () {
    error('Ocurrio un error cambiando el estado');
    cancelChange();
  });
});

onmount('[data-disable-employee-sign="true"]', function () {
  const vacation_sign_check = $(this);
  const employee_sign_check = $(
    'input[name="document_template[employee_sign]"][type="checkbox"]'
  );
  vacation_sign_check.change(function () {
    if (vacation_sign_check.is(':checked') === true) {
      employee_sign_check.prop('disabled', true);
      employee_sign_check.prop('checked', true);
    }
    else {
      employee_sign_check.prop('disabled', false);
      employee_sign_check.prop('checked', false);
    }
  });
});

function tryParseJSON(str) {
  try {
    return JSON.parse(str);
  }
  catch (err) {
    return false;
  }
}

onmount('[data-behavior=schedule_document_template]', function () {
  const $form = $(this);
  const $sbutton = $form.find('[type=submit]');
  const $modal = $('#cron_schedule_modal');

  function setScheduleTime(time) {
    $('td[data-cron-status-id=' + time.id + ']').html(time.cron_job_config);
  }

  function findStatusButton(id) {
    return $('button[data-cron-status-id=' + id + ']');
  }

  function setStarted($button) {
    $button
      .removeClass('btn-destructive')
      .addClass('btn-primary')
      .attr('title', 'Detener');
    $button
      .html('<i class="fa fa-stop"></i><span> Iniciado</span>')
      .prop('disabled', false);
  }

  $form.on('ajax:send', function () {
    $sbutton.prop('disabled', true);
  });
  $form.on('ajax:success', function (event) {
    const result = JSON.parse(event.detail[2].response);
    $modal.modal('hide');
    setStarted(findStatusButton(result.id));
    setScheduleTime(result);
  });
  let $error;
  $form.on('ajax:error', function (event) {
    const response = event.detail[2];
    if ($error) {
      $error.remove();
    }
    if (response.status === 422) {
      const detail = event.detail[0];
      $error = error(detail.errors.join('<br>'), '#schedule_errors');
    }
    else {
      $error = error('Ha ocurrido un error', '#schedule_errors');
    }
  });
});

/*Amplitude analitics*/
onmount('form[class="simple_form new_document_template"]', function () {
  $(this)?.submit(function (e) {
    const templateType = $('#template-type-input')?.val();
    const formatType = $('#document_template_output_type')?.val();
    const requireSigners =
      formatType === 'pdf' ? $('#document_template_signature_sign_required')?.is(':checked') : false;
    const requireReviewer = formatType === 'pdf' ? $('#document_template_required_reviewer')?.is(':checked') : false;
    const withOrder = requireSigners ? $('#document_template_signature_has_order')?.is(':checked') : false;
    const signersQuantity = requireSigners ? $('.document_template_needed_signatures_signature_type').length : 0;

    const dataAmplitude = {
      message: 'Create_Document_Template',
      data: {
        document_template_type: templateType,
        document_format_type: formatType,
        sign_quantity: signersQuantity,
        signature_required: requireSigners,
        signature_order: withOrder,
        document_reviewer: requireReviewer,
      },
    };

    $(e.target).trigger('amplitudeTracking', dataAmplitude);
  });
});

onmount('a#add-new-signee', function () {
  $(this).on('click', function () {
    const button = $(this);
    const hasOrder = $('#document_template_signature_has_order').is(':checked');
    const signeesWrap = hasOrder ?
      $('#document_template_needed_signatures_sortable') :
      $('#document_template_needed_signatures_no_sortable');
    const signees = signeesWrap.children('.card-inputs').filter(function () {
      return !this.classList.contains('hidden');
    });
    const signeesCount = signees.length;
    if (signeesCount < 3) $('.order_numbers').append(`<h1>${signeesCount + 1}</h1>`);
    if (signeesCount === 2) button.addClass('hidden');
  });
});

onmount('.card-inputs', function () {
  const hasOrderCheck = $('#document_template_signature_has_order');
  const parent = $(this);
  const iconParent = $('.card-inputs__content', parent);
  const sortableIcon = document.createElement('i');
  sortableIcon.classList.add('fas', 'fa-ellipsis-v', 'pl-3', 'sortable_helpers');
  if (!hasOrderCheck.is(':checked')) sortableIcon.classList.add('hidden');
  iconParent.addClass('pr-4');
  iconParent.prepend(sortableIcon);
  const btn = $('#remove_signee_btn', parent);
  btn.on('click', () => {
    parent.detach();
    $('a#add-new-signee').removeClass('hidden');
    $('#sortable_order_number > h1').last().detach();
  });
});

onmount('#document_template_signatures_order', () => {
  const hasOrderCheck = $('#document_template_signature_has_order');

  hasOrderCheck.on('click', async function () {
    const checkbox = $(this);
    if (checkbox.is(':checked')) {
      $('#document_template_needed_signatures_no_sortable').removeClass('w-80');
      const cardInputs = $('#document_template_needed_signatures_no_sortable > .card-inputs');
      cardInputs.detach();
      $('#document_template_needed_signatures_sortable').addClass('w-80').append(cardInputs);
      $('a#add-new-signee').data('associationInsertionNode', '#document_template_needed_signatures_sortable');
    }
    else {
      $('#document_template_needed_signatures_sortable').removeClass('w-80');
      const cardInputs = $('#document_template_needed_signatures_sortable > .card-inputs');
      cardInputs.detach();
      $('#document_template_needed_signatures_no_sortable').addClass('w-80').append(cardInputs);
      $('a#add-new-signee').data('associationInsertionNode', '#document_template_needed_signatures_no_sortable');
    }
  });
});

onmount('.signature_type_select', function () {
  // Tipos de firma seleccionados en el formulario de firmas la plantilla
  const signatureTypeSelects = $('.signature_type_select');
  // Función para comprobar si está seleccionado un tipo de firma
  const hasSignatureType = (signatureType) => signatureTypeSelects.get().some(function (element) {
    return $(element).val() === signatureType;
  });

  // Función para comprobar si se debe mostrar la alerta del representante legal
  const checkLegalAgentAlert = () => {
    // Mostrar u ocultar la alerta según corresponda
    if (hasSignatureType('legal_agent')) {
      $('#legal-agent-alert').removeClass('hidden'); // Muestra la alerta
    }
    else {
      $('#legal-agent-alert').addClass('hidden'); // Oculta la alerta
    }
  };

  // Llama a la función cuando cambie cualquiera de los selectores
  $('.signature_type_select').on('change', checkLegalAgentAlert);

});

onmount('#document_template_vacation_require_employee_signature', () => {
  // función para eliminar un selector de firmantes si es que hay más de uno tipo empleado
  function removeLastSignatureCard() {
    const lastSignatureCard = $('.card-inputs').last();
    if (lastSignatureCard.length) {
      lastSignatureCard.detach(); // Elimina el último card del DOM
      $('a#add-new-signee').removeClass('hidden'); // Muestra el botón para añadir firmantes
      $('#sortable_order_number > h1').last().detach(); // Actualiza el número de orden
    }
  }

  // función para habilitar/deshabilitar un selector de firmantes
  function toggleCardInput(cardInput, isChecked) {
    const signatureTypeSelect = cardInput.find('.signature_type_select');

    // Habilita/Deshabilita el selector visualmente
    signatureTypeSelect.prop('disabled', isChecked);

    if (isChecked) {
      // Si el selector está deshabilitado, agrega un campo oculto para enviar el valor
      if (!cardInput.find('input[name="signature_type_hidden"]').length) {
        cardInput.append(
          `<input type="hidden" name="${signatureTypeSelect.attr('name')}" value="${signatureTypeSelect.val()}">`
        );
      }
    }
    else {
      // Si se habilita, elimina el campo oculto (no es necesario)
      cardInput.find(`input[type="hidden"][name="${signatureTypeSelect.attr('name')}"]`).remove();
    }

    cardInput.find('button#remove_signee_btn').prop('disabled', isChecked);
  }

  //función para habilitar/deshabilitar el drag and drop
  function toggleAllSortableCards(isChecked) {
    $('.card-inputs').each(function () {
      $(this).toggleClass('no-drag', isChecked);
      $(this).find('.sortable_helpers').css('visibility', isChecked ? 'hidden' : 'visible');
    });
    $('#document_template_needed_signatures_sortable').toggleClass('sortable', !isChecked);
  }

  // función para habilitar/deshabilitar el checkbox de requisitos de firma
  function toggleSignatureCheckbox(isChecked) {
    $('#document_template_signature_sign_required').prop('disabled', isChecked).change();
    $('input[name="document_template[signature][sign_required]"][type="hidden"]').val(isChecked ? '1' : '0');
  }

  // función para obtener los firmantes actuales del formulario
  function getCompanySignatures(signaturesSelectors) {
    const otherSignatures = [];
    const companySignatures = signaturesSelectors.map((_, el) => {
      const signatureType = $(el).val();
      if (signatureType === 'other') {
        const userIdField = $(el).closest('.row').find('.document_template_needed_signatures_person_id select').val();
        otherSignatures.push({ type: 'other', user_id: userIdField });
      }
      return signatureType;
    }).get().filter(value => value !== 'employee');

    return { companySignatures, otherSignatures };
  }

  // función para actualizar los firmantes del formulario
  function updateSignatures(signaturesSelectors, requiredSignatures, otherSignatures) {
    let otherSignaturesCount = 0;

    signaturesSelectors.each((index, element) => {
      const currentSignatureType = requiredSignatures[index];
      $(element).val(currentSignatureType).change();

      //obtenemos los ids para mostrar/ocultar los selectores de usuarios
      const userIdField = $(element).closest('.row').find('.document_template_needed_signatures_person_id select');
      const uniqueId = userIdField.attr('id').match(/\d+/)[0];
      const toggleSelector = $(`#toggle_${uniqueId}_`);

      if (currentSignatureType === 'other') {
        const associatedOtherSignature = otherSignatures[otherSignaturesCount];
        if (associatedOtherSignature) {
          userIdField.val(associatedOtherSignature.user_id).change(); // Actualiza el campo de usuario
          $(toggleSelector).removeClass('hidden');
        }
        otherSignaturesCount++;
      }
      else {
        $(`#document_template_needed_signatures_attributes_${uniqueId}_person_id`).val('').change();
        $(toggleSelector).addClass('hidden');
      }
    });
    updateCardsPosition();
  }

  // función para actualizar el orden de los firmantes
  function updateCardsPosition() {
    const shouldSetPosition = $('#document_template_signature_has_order').is(':checked');

    $('.card-inputs').each(function (index) {
      const positionInput = $(this).find('input[name*="[position]"]');

      // Establece el valor de posición según el estado del checkbox
      if (shouldSetPosition) {
        positionInput.val(index + 1);
      }
      else {
        positionInput.val('');
      }
    });
  }

  const isAutomaticSignatureRequired = $('#document_template_vacation_require_employee_signature').data(
    'automatic-employee-signature-requirement'
  );

  if (isAutomaticSignatureRequired) {
    const vacationSignCheckbox = $('#document_template_vacation_require_employee_signature');
    const signatureRequiredCheckbox = $('#document_template_signature_sign_required');

    vacationSignCheckbox.on('change', function () {
      const isChecked = $(this).is(':checked');
      const isSignatureRequiredChecked = signatureRequiredCheckbox.is(':checked');
      const signaturesSelectors = $('.signature_type_select');

      const { companySignatures, otherSignatures } = isSignatureRequiredChecked ?
        getCompanySignatures(signaturesSelectors) :
        { companySignatures: [], otherSignatures: [] };

      if (!isSignatureRequiredChecked) {signatureRequiredCheckbox.prop('checked', true).change();}

      if (isChecked) {
        companySignatures.unshift('employee');
        const requiredSignatures = companySignatures.slice(0, 3);

        if (requiredSignatures.length > signaturesSelectors.length) {
          $('a#add-new-signee').trigger('click');
        }
        else if (requiredSignatures.length < signaturesSelectors.length) {
          for (let i = 0; i < signaturesSelectors.length - requiredSignatures.length; i++) {
            removeLastSignatureCard();
          }
        }

        updateSignatures($('.signature_type_select'), requiredSignatures, otherSignatures);
      }

      toggleCardInput($('.card-inputs').first(), isChecked);
      toggleAllSortableCards(isChecked);
      toggleSignatureCheckbox(isChecked);
    });

    if (vacationSignCheckbox.is(':checked')) {
      vacationSignCheckbox.trigger('change');
    }

  }

});

onmount('#template_preview_button', async function () {
  const { loadCKeditor, CKeditorVersion } = await import('@assets/javascripts/components/ckeditor/load');
  if (CKeditorVersion() === '4') await loadCKeditor();
  $(document).on('beforeAjaxSend.ic', function (_event, ajaxSetup, _elt) {
    if (!ajaxSetup.url.includes('/document_templates/')) return;
    const content = CKeditorVersion() === '4'
      ? CKEDITOR.instances['document_template_content'].getData() : $('#document_template_content').val();
    ajaxSetup.data = ajaxSetup.data + '&document_template%5Bcontent%5D=' +
    encodeURIComponent(content);
  });
});
