/** @file Inicialización de Autocompletado de Google Places */
import onmount from 'onmount';

onmount('[data-autocomplete-google-places]', async function () {
  const parameters = $(this).data('autocomplete-google-places');
  const googlePlacesApiKey = parameters['google_places_api_key'];
  if(!googlePlacesApiKey) return;
  const form = $(this).closest('form');

  const { Loader } = await import('@googlemaps/js-api-loader');
  const loader = new Loader({
    apiKey: googlePlacesApiKey,
  });

  await loader.importLibrary('places');

  const autocompleteEvent = new google.maps.places.Autocomplete(this, {
    types: ['address'],
    componentRestrictions: { country: parameters['country'] },
  });

  autocompleteEvent.addListener('place_changed', () => {
    const placeObtained = autocompleteEvent.getPlace();
    clearInputs(form);
    if (placeObtained.geometry) setCoordinatesToInputs(form, placeObtained.geometry);
    if (placeObtained.address_components) setAddressDetailsToInputs(form, placeObtained.address_components);
  });
});

function clearInputs(form) {
  Array.from(form.find('#google-places-inputs input')).forEach((input) => $(input).val(''));
}

function setCoordinatesToInputs(form, geometry) {
  const latitudeInput = form.find('input[name*="latitude"]');
  const longitudeInput = form.find('input[name*="longitude"]');
  if (latitudeInput) latitudeInput.val(geometry.location.lat().toFixed(8));
  if (longitudeInput) longitudeInput.val(geometry.location.lng().toFixed(8));
}

function setAddressDetailsToInputs(form, addressComponents) {
  const componentMapping = {
    route: 'address_name',
    street_number: 'address_number',
    postal_code: 'postcode',
    locality: 'locality',
  };

  addressComponents.forEach(component => {
    const componentType = component.types[0];
    const inputId = componentMapping[componentType];
    const valueParameter = component.short_name || component.long_name;

    if (inputId && valueParameter) {
      if(componentType === 'locality') {
        setLocationToInputSelect(valueParameter);
      }
      else {
        const inputElement = form.find(`input[name*="${inputId}"]`);
        if(inputElement) inputElement.val(valueParameter);
      }
    }
  });
}

function setLocationToInputSelect(locationName) {
  const $vueTreeselectContainer = $('.select-location');
  if ($vueTreeselectContainer.length) {
    const vueInstance = $vueTreeselectContainer[0].__vue__;
    if(vueInstance) vueInstance.setLocationByName(locationName);
  }
}
