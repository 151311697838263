/** @file TODO: documentar */
/**
 *
 * Muestra/Oculta una lista de elementos con checkboxes creada con DataTables.
 *
 * El atributo 'data-multi-select-toggle' se aplica a la acción, un enlace.
 */

import onmount from 'onmount';

/** Funcion que cuenta los elementos seleccionados en todas las paginaciones */
function checkOptionSelected($table) {
  let checked = 0;
  $table.rows().every(function () {
    const rowNode = this.node();
    if ($(rowNode).find('input[type="checkbox"]').prop('checked')) {
      checked++;
    }
  });
  return checked;
}

async function setupToggleLink(link, $target) {
  const {
    prepareLinkContainer,
    handleLinkContainer,
  } = await import('./multi-select-common.js');
  const listItem = link.parent();
  prepareLinkContainer(listItem);
  link.on('click', function (e) {
    const $datatable = $target.data('DataTable');
    const showSelected = $(this).data('show-selected');
    const checked = checkOptionSelected($datatable);
    if (checked === 0) { return false; }
    e.preventDefault();
    link.data('show-selected', showSelected);
    $(this).data('show-selected', !showSelected);

    $.fn.dataTable.ext.search.push(function (_settings, _data, dataIndex) {
      const row = $datatable.row(dataIndex).node();
      if(link.data('show-selected')) {
        return !!$(row).find('input').prop('checked');
      }
      else {
        return true;
      }
    });
    $datatable.draw();
    // con esto evitamos que persista el filtro
    $.fn.dataTable.ext.search.pop();
  });

  $target.on('checkboxCount', (event) => {
    handleLinkContainer(listItem, event.count);
    link.find('span').text('(' + event.count + ')');
  });
}

onmount('[data-multi-select-action="toggle"]', async function () {
  const $table = $(this.getAttribute('data-multi-select-source'));
  await $table.waitDataTable();
  const link = $(this);

  setupToggleLink(link, $table);
});
