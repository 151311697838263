/** @file Controla las acciones de elementos relacionados a tareas pendientes */
import onmount, { $ } from 'onmount';

const mounted = { 'mobile': false, 'desktop': false };

// Se encarga de actualizar el widget de Tareas Pendientes del portal cuando ocurre un cambio
// desde el side bar de Tareas Pendientes.
onmount('#pending-aside', function () {

  // Se elimina el ic-deps del widget de TP para que no se actualice 2 veces al archivar una TP
  // desde el sidebar
  var pendingWidget =  $('#pending-task-widget');
  const widgetPath = pendingWidget.attr('ic-deps');
  pendingWidget.attr('ic-deps', 'ignore');

  var sidebar = document.querySelector('.aside-base');
  var sidebarContent = document.querySelector('#pending-aside');

  var observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      // Detecta si hubo un cambio en la lista de Tareas Pendientes
      if ($(mutation.target).attr('id') === 'pending-aside' && mutation.addedNodes.length > 0) {
        // eslint-disable-next-line no-undef
        Intercooler.triggerRequest($('#pending-task-widget'));
      }

      // Una vez que se cierra el sidebar, se le agrega nuevamente el ic-deps al widget
      if ($(mutation.target).hasClass('aside-base')) {
        pendingWidget.attr('ic-deps', widgetPath);
      }
    });
  });

  observer.observe(sidebar, { childList: true });
  observer.observe(sidebarContent, { childList: true });
});

const openPendingTaskSidebar = async function (device) {
  if (mounted[device]) return;

  mounted[device] = true;

  const $toogleButton = (device === 'desktop') ? $('#toggle-aside-reminder-navbar-loaded') : $('#tareas-tab');
  const locationSearch = new URLSearchParams(window.location.search);
  const deliveryQuery = locationSearch.get('delivery');
  const refererQuery = locationSearch.get('referer');

  if (!deliveryQuery || !refererQuery) return;

  if (deliveryQuery === 'pending_task_reminder' && refererQuery === 'mail') {
    $toogleButton.click();
  }
};

const isMobile = function () {
  return window.matchMedia('(max-width: 960px)').matches;
};

isMobile()
  ? onmount('#tareas-tab', () => openPendingTaskSidebar('mobile'))
  : onmount('#toggle-aside-reminder-navbar-loaded', () => openPendingTaskSidebar('desktop'));

window.addEventListener('resize', () => {
  isMobile()
    ? openPendingTaskSidebar('mobile')
    : openPendingTaskSidebar('desktop');
});
