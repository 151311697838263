/** @file Maneja el comportamiento de los links de acciones de la tabla de documentos */
import onmount from 'onmount';

onmount('#employees_table_modal', function () {
  const $generateFilesButton = $('#generate_files_button');
  const $goBackButton = $('#back_button');
  const $confirmButton = $('#confirm_button');
  const $documentTemplateSelect = $('#multiple_document_document_template_id');
  const $hiddenDocumentsGeneration = $('#multiple_document_hidden_documents_generation');
  const $startWorkflow = $('#multiple_document_start_workflow');
  const $warningMessage = $('#warning_message_for_checkbox_visibility');
  var selectedEmployeesRows = $('.generate_documents').DataTable().rows('.selected').nodes().toArray();
  var selectedEmployees = [];

  const configurableTemplates = getconfigurableTemplates(
    $documentTemplateSelect
  );
  var selectedTemplateId = getSelectedTemplateId($documentTemplateSelect);
  let checkedDocumentVisible = $hiddenDocumentsGeneration?.prop('checked');
  let selectedTemplateAmplitudeData = getSelectedTemplateAmplitudeData($documentTemplateSelect);

  displayModal('select_signers_modal', false);

  $documentTemplateSelect.on('change', function () {
    selectedTemplateId = getSelectedTemplateId($documentTemplateSelect);
  });

  $generateFilesButton.on('click', function (e) {
    selectedEmployeesRows = $('.generate_documents').DataTable().rows('.selected').nodes().toArray();
    selectedEmployees = getSelectedEmployees();
    checkedDocumentVisible = $hiddenDocumentsGeneration?.prop('checked');
    selectedTemplateAmplitudeData = getSelectedTemplateAmplitudeData($documentTemplateSelect);

    e.preventDefault();
    if (isConfigurable(selectedTemplateId, configurableTemplates)) {
      filterTemplates();
      filterCompanies();
      workflowCheckboxLayout();
      showErrorEmptyLegalAgents();
      displayModal('employees_table_modal', false);
      displayModal('select_signers_modal', true);
      const documentSpecificationsElement = document.getElementById('document-specifications');
      if (documentSpecificationsElement) {
        doRequest('/document_templates/signature_requirements', 'get',
          {
            id: selectedTemplateId,
            employee_ids: selectedEmployees,
          }).then((response) => {
          document.getElementById('document-specifications').innerHTML = response;
          showErrorEmptyLegalAgents();
        });
      }
    }
    else {
      const payload = buildPayload();
      delete payload.multiple_document.legal_agent_ids;
      doRequest($confirmButton.attr('url'), 'post', payload);
    }
    //Amplitude document_create_button_clicked (Create_Document)
    $(e.target).trigger('amplitudeTracking', {
      message: 'document_create_button_clicked',
      data: {
        source: 'Documentos',
        document_batch: selectedEmployees.length,
        document_creator_role: 'Admin',
        document_category: selectedTemplateAmplitudeData['template_type'],
        document_visible: checkedDocumentVisible,
        document_reviewer: selectedTemplateAmplitudeData['template_reviewer'],
        signature_required: selectedTemplateAmplitudeData['template_need_signature'],
        signature_order: selectedTemplateAmplitudeData['template_signature_order'],
      },
    });
  });

  $goBackButton.on('click', function (e) {
    e.preventDefault();
    resetSelections();
    displayModal('employees_table_modal', true);
    displayModal('select_signers_modal', false);
  });

  $confirmButton.on('click', function (e) {
    if (showErrorSameLegalAgents() === true) return;

    e.preventDefault();
    $confirmButton.prop('disabled', true);
    doRequest($confirmButton.attr('url'), 'post', buildPayload());

    //Amplitude End_Create_Document
    $(e.target).trigger('amplitudeTracking', {
      message: 'End_Create_Document',
      data: {
        document_batch: selectedEmployees.length,
        document_creator_role: 'Admin',
        document_category: selectedTemplateAmplitudeData['template_type'],
        document_visible: checkedDocumentVisible,
        document_reviewer: selectedTemplateAmplitudeData['template_reviewer'],
        signature_required: selectedTemplateAmplitudeData['template_need_signature'],
        signature_order: selectedTemplateAmplitudeData['template_signature_order'],
        automatic_signature_flow: $startWorkflow?.prop('checked'),
      },
    });
  });

  function resetSelections() {
    $('div[type="company"]').removeClass('d-none');
    $('div[type="template"]').removeClass('d-none');
  }

  function getSelectedCompanies() {
    const companyIds = selectedEmployeesRows.map(
      n => n.getElementsByTagName('input')[0].getAttribute('data-company-id'));
    return companyIds;
  }

  function getSelectedEmployees() {
    const employeeIds = selectedEmployeesRows.map(n => n.getElementsByTagName('input')[0].getAttribute('value'));
    return employeeIds;
  }

  function getLegalAgentIds() {
    const legalAgentDiv = $('div[type="template"]')
      .not('.d-none')
      .find('div[type="company"]')
      .not('.d-none')
      .find('#multiple_document_legal_agent, #multiple_document_legal_agent');
    const legalAgentIds = $('option:selected', legalAgentDiv)
      .toArray()
      .map((option) => option.value);
    return [...new Set(legalAgentIds)];
  }

  function filterCompanies() {
    var companyIds = getSelectedCompanies()
      .map((id) => '#' + id)
      .join(',');
    $('div[type="company"]')
      .not(companyIds)
      .addClass('d-none');
  }

  function filterTemplates() {
    const $templatesToFilter = $('div[type="template"]').not(
      `#${selectedTemplateId}`
    );
    $templatesToFilter.addClass('d-none');
  }

  function getSelectedTemplateId(select) {
    return parseInt($('option:selected', select)[0].value);
  }

  function getSelectedTemplateAmplitudeData(select) {
    return JSON.parse($('option:selected', select).attr('data_amplitude'));
  }

  function getconfigurableTemplates(select) {
    return JSON.parse(select.attr('data-configurable'));
  }

  function isConfigurable(templateId, configurableList) {
    return configurableList.includes(templateId);
  }

  function companiesWithoutSufficientLegalAgents(companiesIds) {
    const neededSignatures = parseInt(
      $('[type="template"]:not([class^="d-none"]):first').attr('data-needed-legal-agent-signatures')
    );
    return companiesIds.some(id => parseInt($(`[type="company"]#${id}`).attr('data-legal-agents')) < neededSignatures);
  }

  function cleanErrorLeganAgent() {
    $('#plural_empty_legal_agent_error').addClass('hidden');
    $('#single_empty_legal_agent_error').addClass('hidden');
    $('#same_legal_agents_assigned_error').addClass('hidden');
    $('#confirm_button').removeClass('disabled');
  }

  function showErrorEmptyLegalAgents() {
    cleanErrorLeganAgent();
    const companiesIds = getSelectedCompanies();
    const missingLegalAgents = companiesWithoutSufficientLegalAgents(companiesIds);
    if (missingLegalAgents) {
      const errorId = companiesIds.length > 1 ? '#plural_empty_legal_agent_error' : '#single_empty_legal_agent_error';
      $(errorId).removeClass('hidden');
      $('#confirm_button').addClass('disabled');
    }
  }

  function workflowCheckboxLayout() {
    const checkedHiddenDocuments = $hiddenDocumentsGeneration?.prop('checked');
    $startWorkflow.attr('disabled', !checkedHiddenDocuments);
    if(checkedHiddenDocuments === false) {
      $warningMessage.removeClass('hidden');
      $startWorkflow.prop('checked', false);
    }
    else {
      $warningMessage.addClass('hidden');
    }
  }

  function displayModal(modalName, show) {
    const element = document.getElementById(modalName);
    if (show === true) {
      element.classList.remove('d-none');
    }
    if (show === false) {
      element.classList.add('d-none');
    }
  }

  function buildPayload() {
    return ({
      multiple_document:
      { employee_ids: selectedEmployees,
        document_template_id: selectedTemplateId,
        legal_agent_ids: getLegalAgentIds(),
        start_workflow: $startWorkflow.prop('checked'),
        hidden_documents_generation: $hiddenDocumentsGeneration?.prop('checked'),
      },
    });
  }

  function doRequest(url, method, data) {
    return $.ajax({
      url: url,
      method: method,
      data: data,
    });
  }

  function showErrorSameLegalAgents() {
    const legalAgents = $('div[type="template"]')
      .not('.d-none')
      .find('div[type="company"]')
      .not('.d-none')
      .find('#multiple_document_legal_agent, #multiple_document_legal_agent');
    if (legalAgents.length > 1) {
      if (legalAgents[0].value === legalAgents[1].value) {
        $('#same_legal_agents_assigned_error').removeClass('hidden');
        return true;
      }
    }
    return false;
  }
});
